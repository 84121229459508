.main_login {
  display: flex;
  width: 100%;
  height: 100vh;
}
.login {
  display: flex;
  /* justify-content: center;
  flex-direction: column; */
  width: 55%;
}
.form {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.inputs{
  width: 50%;
}
.icones_idioma {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: 10%;
}
.ccr_image{
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  width: 100%;
  height: 100vh;
}
.btn_login {
  font-family: Montserrat-Bold;
  border-radius: 50px;
  font-size: 12px;
  background-color: #68B880;
  color: white;
  width: 100%;
  letter-spacing: 1.2px;

  height: 7vh;
  border: none;
  margin-top: 5%;
  cursor: pointer;
  outline: none;
}
.btn_login:hover{
  background-color: #4ca165;
}
.input_login {
  border-radius: 50px;
  height: 8vh;
  width: 100%;
  margin-bottom: 5%;
  border: solid 1px #ddd;
  box-shadow: 0px 2px 5px #0000000D;
  padding: 0.7rem 1.2rem;
  font-family: Montserrat-Bold;
  font-weight: 800;
  color: #404179;
  opacity: 0.7;
}
.input_login:focus{
  outline: none;
  opacity: 1;
}
#p_senha {
  color: #707070;
  text-align: center;
  font-family: Montserrat-Light;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 10%;
}
#p_login {
  text-align: center;
  font-size: 13px;
  color: #898A8F;
  font-family: Montserrat-Regular;
}
.p_lembrar {
  color: #707070;
  text-align: center;
  font-family: Montserrat-Light;
  font-size: 11px;

}
.check {
  display: flex;
  justify-content: space-around;
}
.idioma {
  width: 2rem;
  height: 2rem;
  margin-right: 6%;
  cursor: pointer;
}
.input_login::placeholder{
  text-align: center;
}
.login_img {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #9F270EF2;
  opacity: 0.9;
  width: 100%;
  height: 100vh;
  border-radius: 0 18px 18px 0;
}
.div_image {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 0 18px 18px 0;
  width: 100%;
  height: 100vh;
  background: transparent url('./../../assets/img/imagem2.png') 10% 50% no-repeat padding-box;
}
.login_left {
  display: flex;
  justify-content: center;
  width: 45%;
}
.logo_login {
  width: 100%;
  height: 100vh;
  background: transparent url('./../../assets/img/ccr.svg') 0% 0% no-repeat padding-box;
}
#log {
  width: 20%;
}

@media only screen and (max-width: 600px) {
  .login_left{
    width: 100%;
    height: 60%;
  }
  .div_image{
    height: 80%;
    border-radius: 0 0 18px 18px;
  }
  .login {
    width: 100%;
    height: 20%
  }
  .main_login {
    flex-direction: column;
    height: 100vh;
  }
  .inputs {
    width: 80%;
    margin-bottom: 2%;
  }
  .icones_idioma {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
  }
  #p_login {
    margin-top: 10%;
  }
}
