.switch {
  position: relative;
  display: inline-block;
  width: 12%;
  height: 2vh;
}
.geral_empresa {
  display: flex;
  align-items: center;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.vl {
  border-left: 1px solid lightgray;
  height: 20px;
  margin-left: 5%;
  margin-right: 5%;

}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 3vh;
  width: 40%;
  left: 1%;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
#tog {
  color: #707070;
  font-size: 9px;
  font: normal normal 300 14px/18px Montserrat-Regular;
}
@media only screen and (max-width: 600px) {
  #collapse {
    display: none;
  }
  .conteudo {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  #collapse {
    width: 80%;
  }
}
