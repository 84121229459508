.periodo {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.feriados{
  display: -ms-flex;
	display: -webkit-flex;
	display: flex;
  width: 50%;
}
.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 80%;
  justify-content: center;
  margin-top: 5%;
}

.column {
  display: flex;
  flex-direction: column;
  flex: 1;
}
