.layer_mini {
  background-color: #9F270EF2;
  opacity: 0.9;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0 34px 34px 0;
  padding: 0;
}
.minisidebar {
  width: 8%;
  border-radius: 0 34px 34px 0;
  display: flex;
  min-height: 100vh;
  justify-content: center;
  background:url('../../assets/img/imagem2.png');
}
.logo {
  margin-top: 40%;
  margin-bottom: 10%;
}
.white_paragraph{
  color: white;
}
#home_icon {
  width: 25px;
  height: 25px;
}
.list-mini{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
}
.list-mini li {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 0;
  --ggs: 0.7;
}
.icon_open{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #989898;
  top: 50%;
  left: 8%;
  border-radius: 50%;
  cursor: pointer;
}
.line-bar{
  align-self: center;
  background-color: #000;
  border: none;
  margin: 20%;
  width: 30%;
  height: 1px;
}
@media only screen and (max-width: 600px) {
  .icon_open{
    left: 3%;
  }
}
