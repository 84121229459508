 #customers {
  font-family: Montserrat-Regular;
  border-collapse: collapse;
  width: 100%;
}

#customers td{
  padding: 0% 2%;
  font-size: 12px;
  height: 8vh;
}

#customers tr:nth-child(even){
  background-color: #F7FAFC;
  height: 8vh;
}

#customers thead {
  font-size: 10px;
  background-color: #F1F3F9;
  color: #8898AA;
}

th {
  height: 5vh;
  padding: 0% 2%;
}
td{
  word-wrap: break-word;
  word-break: break-word;
}
#table {
  width: 100%;
  text-align: left;
}
#input_table {
  display: flex;
  width: 100%;
  margin-top: 2%;
  margin-bottom: 1%;
  align-items: center;
}
#input_search {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #0000001C;
  border: 1px solid #D8D8D8;
  border-radius: 50px;
  width: 55%;
  height: 5vh;
  margin-left: 5%;
}
#input_search::placeholder {
  color: #8288AC;
}

#btn_search {
  border-radius: 50px;
  background-color: #68B880;
  color: white;
  width: 20%;
  height: 5vh;
  letter-spacing: 1.2px;
  font-size: 10px;
  box-shadow: 0px 3px 6px #2C28281C;
  font-family: Montserrat-Bold;
  opacity: 1;
  background-color: white;
  color: #172B4D;
  margin-left: 3%;
  border: 1px solid #172B4D;
}
#pagination {
  display: flex;
  justify-content: flex-end;
  margin-right: 5%;
  margin-top: 20%;
}
.field{
  width: 100%;
  display: flex;
  justify-content: space-around;
  /* justify-content: center; */
}
.field_input {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.field_input .icon {
  position: absolute;
  left: 18%;
  top: 35%;
}
@media only screen and (max-width: 600px) {
  #input_search {
    width: 30%;
  }
  #btn_search {
    width: 45%;
  }
  #input_table {
    justify-content: space-around;
  }
  .field_input .icon {
    position: absolute;
    left: 20%;
    top: 35%;
  }
}
