.painel_geral {
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 0 2px 2px lightgray;
  width: 100%;
  padding-top: 0;
}
#btn_reenvio {
  text-align: center;
  font: Montserrat-Bold;
  letter-spacing: 0.65px;
  color: #FFFFFF;
  opacity: 1;
  background: #2DCE98 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #0000001C;
  border-radius: 50px;
  border: none;
  opacity: 1;
  height: 5vh;
  width: 18%;
  margin-left: 5%;
  margin-bottom: 5%;
}

@media only screen and (max-width: 600px) {
  #btn_reenvio {
    width: 60%;
    height: 5vh;
    margin-left: 0;
  }
  #div_btn_reenvio {
    display: flex;
    justify-content: center;
  }
}
