.textarea {
  height: 30vh;
  border-radius: 14px;
  box-shadow: 0px 2px 5px #0000000D;
  border: 1px solid #ECECEC;
  margin-top: 3%;
  width: 100%;
}
.nota {
  width: 50%;
  display: flex;
  justify-content: center;
  margin-top: 3%;
  align-items: center;
}
