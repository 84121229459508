.btn_div {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 5%;
}
.btn {
  border-radius: 50px;
  background-color: #68B880;
  color: white;
  width: 30%;
  height: 5vh;
  letter-spacing: 1.2px;
  margin-top: 5%;
  margin-right: 4%;
  font-size: 10px;
  box-shadow: 0px 3px 6px #2C28281C;
  font-family: Montserrat-Bold;
  opacity: 1;
}
.limpar {
  background-color: white;
  color: #F53C56;
  border: 1px solid #F53C56;
}
.add {
  border: none;
}
