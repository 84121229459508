.atalhos {
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 5px #0000000D;
  display: flex;
  margin: 5%;
  width: 80%;
  border-radius: 50px;
  height: 9vh;
  border: 1px solid #ddd;
  cursor: pointer;
}
.atalhos:hover{
  box-shadow: 0px 6px 15px #0000000D;
}
#span_sub {
  font-size: 10px;
  color: #BCBDC3;
}
#span_titulo {
  font-size: 15px;
  font-family: Montserrat-Bold;
  color: #8288AC;
}

@media only screen and (max-width: 600px) {
  .atalhos {
    width: 100%;
  }
}
