.content-main{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin-top: 5%;

  padding: 0 2rem;
}
.atalho {
  width: 90%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.home_div{
  width: 70%;
}
#p_atalhos {
  align-self: flex-start;
  color: rgba(67,66,93, 0.7);
  font-family: Montserrat-Regular;
  font-weight: 700;
  margin-left: 4%;
}
.h3_home{
  font-family: Montserrat-Thin;
  color: #707070;
}
.content_bottom{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
}
.quote{
  position: absolute;
  top: 5%;
  left: 15rem;
  z-index: -1;
  position: absolute;
  opacity: 0.75;
  filter:alpha(opacity=75);
  width: 20rem;
  height: 20rem;
}
@media only screen and (max-width: 600px) {
  .atalho {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .content_bottom {
    width: 100%;
  }
  .home_div {
    width: 100%;
    font-size: 10px;
  }
  #p_atalhos{
    text-align: center;
  }
}
