.info {
  width: 92%;
  display: flex;
  height: fit-content;
  min-height: 100vh;
  justify-content: center;
  background: #F8F8F8 0% 0% no-repeat padding-box;
}
.panel {
  margin-top: 1.5rem;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 0 2px 2px lightgray;
  width: 80%;
  padding-top: 0;
}
section{
  display: flex;
  justify-content: space-around;
  padding-left: 3%;
}
section div{
  width: 100%;
  margin-right: 1rem;
}
section div:before{
  content: attr(label);
  font-family: Montserrat-Regular;
  font-size: 14px;
  font-weight: 700;
  color: #172B4D;
}
#infos {
  font-family: Montserrat-Bold;
  color: #172B4D;
  letter-spacing: 1.5px;
  margin-left: 2%;
  padding-top: 3%;
}
/* hr{
  margin-bottom: 1.5rem;
  width: 100%;
  background-color: #bfd7e7;
}*/
.panel div{
  padding: 3% 0;
}
.panel>div:nth-child(even){
  background-color: #F7FAFC;
}
.color_div{
  background-color: #F7FAFC;
}
