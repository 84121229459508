.modal {
    display: flex;
    justify-content: center;
    align-items:center;
    position: fixed;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);

  }

  .modal-main {
    position:fixed;
    background: white;
    width: 80%;
    height: auto;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
  }

  .display-block {
    display: flex;
  }

  .display-none {
    display: none;
  }

  .modal-window {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
    padding: 2rem;
    width: 20rem;
    height: 24rem;
    background-color: #fff;
  }

  #close_modal {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }