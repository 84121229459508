*{
  box-sizing: border-box;
}

/* fontes  */
@font-face {
  font-family: Montserrat-Regular;
  src: url('Montserrat-Regular.ttf');
}
@font-face {
  font-family: Montserrat-Thin;
  src: url('Montserrat-Thin.ttf');
}
@font-face {
  font-family: Montserrat-Light;
  src: url('Montserrat-Light.ttf');
}
@font-face {
  font-family: Montserrat-Bold;
  src: url('Montserrat-Bold.ttf');
}

a{
  text-decoration: none;
}
a:link {
  color: inherit;
}
a:visited {
  color: inherit;
}
a:hover {
  color: inherit;
}

h1 {
  font-family: Montserrat-Bold;
  color: #707070;
}
h4 {
  font-family: Montserrat-Thin;
  color: #707070;
}
h3 {
  font-family: Montserrat-Bold;
  color: #172B4D;

}
#title {
  text-align: center;
  font-family: Montserrat-Bold;
  color: #172B4D;
  margin-bottom: 9%;
}
.white_paragraph{
  color: white;
}
.p_sidebar {
  font-style: oblique;
  color: white;
}
ul{
  list-style-type: none;
  padding-left: 0;
}
li{
  font-family: Montserrat-Regular;
  color: white;
  font-size: 14px;
  margin-bottom: 15%;
  text-align: center;
}

#home_icon {
  width: 25px;
  height: 25px;
}


/* estruturas */
.main{
  display: flex;
  width: 100%;
}
.content {
  display: flex;
  justify-content: center;
  width: 92%;
}

.layer {
  background-color: #9F270EF2;
  opacity: 0.9;
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 0 34px 34px 0;
}
.conteudo{
  width: 80%;
}



/* componentes */
#span_sub {
  font-size: 10px;
  color: #BCBDC3;
}
#span_titulo {
  font-size: 15px;
  font-family: Montserrat-Bold;
  color: #8288AC;
}
.atalhos {
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: 0px 2px 5px #0000000D;
  display: flex;
  margin: 3%;
  border-radius: 50px;
  height: 10vh;
  border: 1px solid #ddd;
  cursor: pointer;
}
.atalhos:hover{
  box-shadow: 0px 6px 15px #0000000D;
}
.logo_ccr {
  display: flex;
}
.logo {
  margin-top: 30%;
  margin-bottom: 10%;
}
.input {
  border-radius: 14px;
  box-shadow: 0px 4px 10px #0000000D;
  border: 1px solid #ddd;
  margin-bottom: 1rem;
  height: 6vh;
  width: 100%;
}


/* pages */
main{
  display: flex;
}
.left {
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3%;
}
.tabela {
  display: flex;
  justify-content: center;
  /* height: 100vh; */
  border-radius: 10px;
  box-shadow: -2px 2px 15px #2C282826;
  box-sizing: border-box;
  width: 65%;
}
::placeholder {
  color: #172B4D;
  padding-left: 5%;
}


/* buttons */

.btn_div {
  width: 100%;
  display: flex;
  justify-content: center;
}
#status {
  height: 1.3vh;
  width: 18%;
  border-radius: 50%;
}

input{
  padding: 1.2rem 1.2rem;
}
input:focus{
  outline: none;
  opacity: 1;
}


.modal {
  display: flex;
  justify-content: center;
  align-items:center;
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);

}

.modal-main {
  position:fixed;
  background: white;
  width: 80%;
  height: auto;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}

.display-block {
  display: flex;
}

.display-none {
  display: none;
}

.modal-window{
  border-radius: 10px;
  padding: 2rem;
  width: 20rem;
  height: 22rem;
  background-color: #fff;
}

.btn-search{
  width: 100%;
  height: 6vh;
  border-radius: 14px;
  outline:none;
  margin: 1rem 0;
  border: none;
	background-color: #7686EB;
	color: #fff;
	padding: 0;
	cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .left {
    width: 100%;
  }
  .content {
    flex-direction: column;
    display: flex;
    justify-content: right;
  }
  .tabela {
    width: 100%;
  }
 .header_div {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: -2px 2px 15px #2C282826;
    border-radius: 10px;
    height: 10vh;
    align-items: center;
    display: flex;
    margin-bottom: 10%;
    width: 100%;
    justify-content: center;
  }
}
