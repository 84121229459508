.sidebar {
  margin: 0;
  width: 25%;
  border-radius: 0 34px 34px 0;
  display: flex;
  min-height: 100vh;
  justify-content: center;
  background:url('../../assets/img/imagem2.png');
}
.line{
  align-self: center;
  background-color: #000;
  border: none;
  margin: 10%;
  width: 90%;
  height: 1px;
}
.p_sidebar {
  display: flex;
  align-items: baseline;
  font-style: oblique;
  color: #FFFFFF80;
  font-size: 14px;
  margin: 0;
  width: 100%;
}
.p_sidebar:after{
  content: '';
  height: 1px;
  width:100%;
  border-top: 1.5px solid #7c2a14;
  margin: 0 0.5rem;
}
.header{
  display: flex;
  justify-content: center;
}
.header-logo{
  width: 20%;
}
.header-greeting:before{
  content: "";
  height: 3rem;
  width: 1px;
  border-left: 1px solid #fff;
  margin: 0 1rem;
}
.header-greeting{
  display: flex;
  align-items: center;
}
.layer{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  padding: 2rem 0 2rem 1rem;
}
.list-full{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 1rem 0 1rem 2rem;
}

.list-full li{
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  cursor: pointer;
  margin: 0.2rem 0;
  font-size: 12px;
  --ggs: 0.7;
}
.list-full li i{
  margin-right: 1rem;
}

.strong{
  font-weight: bold;
  font-size: 0.8rem;
  color: #fff !important;
  margin: 0.2rem 0 !important;
}

.greeting{
  display: flex;
  flex-direction: column;
  margin: 0;
}
.greeting li{
  margin: 0;
}
.home{
  display: flex;
  color: #fff;
  justify-content: flex-start;
  align-items: baseline;
  align-self: flex-start;
  margin: 0;
  font-size: 20px;
}
.home i{
  margin: 0 1rem;
}
/* hr{
  background-color: black;
  margin: 0.7rem 0;
  width: 70%;
  height: 1px;
} */
.logout{
  display: flex;
  align-items: center;
  color: #fff;
}
.logout i{
  margin: 0 1rem;
}
.icon_close{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #989898;
  top: 50%;
  left: 22%;
  border-radius: 50%;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .sidebar {
    position: absolute;
    width: 100%;
  }
  .icon_close{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #989898;
    top: 50%;
    left: 90%;
    border-radius: 50%;
    cursor: pointer;
  }
}
