.btn_info {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 5%;
}
.buttons {
  border-radius: 50px;
  background-color: #7686EB;
  color: white;
  width: 16%;
  height: 5vh;
  letter-spacing: 1.2px;
  margin-top: 5%;
  margin-right: 4%;
  font-size: 10px;
  box-shadow: 0px 3px 6px #2C28281C;
  font-family: Montserrat-Bold;
  opacity: 1;
}
.clear {
  background-color: white;
  color: #F53C56;
  border: 1px solid #F53C56;
}
.cadastrar {
  border: none;
}
@media only screen and (max-width: 600px) {
  .buttons {
    width: 40%;
  }
}
