.edit_aparencia {
  height: 3vh;
  width: 15%;
  border-radius: 50%;
}
.painel {
  margin-top: 4%;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0 0 2px 2px lightgray;
  width: 70%;
  padding-top: 0;
}



/* Modal */
.title{
  color: #4D4F5C;
  font-size: 22px;
}
.title:before{
  content: attr(label);
  color: #4D4F5C;
  font-size: 12px;
}
.line-title{
  height: 2px;
  background-color: #E9E9F0;
}
.hex-color{
  height: 20px;
  width: 70px;
  padding:0;
  border: 1.5px solid #D9DCE2;
  border-radius: 3px;

}
.label{
  color:#172B4D;
  font-size:14px;
  font-weight: 500;
}
.input-section{
  display: flex;
  flex-direction: column;
}
.section{
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;
}
.cores{
  display: flex;
  justify-content: space-around;
  margin: 0;
}
.cores *{
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0 5px;
}
.cor1{
  background-color: #F53C56;
}
.cor2{
  background-color: #2DCE98;
}
.cor3{
  background-color: #7764E4;
}
.cor4{
  background-color: #11CDEF;
}
.window{
  display: flex;
  flex-direction: column;
}
.btn_aparencia{
  font-family: Montserrat-Bold;
  border-radius: 50px;
  font-size: 12px;
  width: 100%;
  letter-spacing: 1.2px;
  height: 7vh;
  border: none;
  margin-top: 5%;
  cursor: pointer;
  outline: none;
}
.btn-primary{
  background-color: #68B880;
  color: white;
}
.btn-secondary{
  background-color: transparent;
  color: #D8D8D8;
  border: 2px solid #D8D8D8;
}

.zone {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #F0F2F8;
  border-radius: 10px;
  width: 110px;
  height: 110px;
  text-align: center;
  color: #4D4F5C;
  z-index: 20;
  font-size: 8px;
  padding: 20px 10px;
}
.zone .selectFile {
  height: 30px;
  width: 100px;
}
.zone label {
  background: #3B86FF;
  color: #fff;
  display: inline-block;
  font-size: 10px;
  padding: 4px;
  font-weight: 400;
  border-radius: 4px;
}
.zone input[type=file] {
  opacity: 0;
}

@media only screen and (max-width: 600px) {
  .painel {
    width: 90%;
  }

}
