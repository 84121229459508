#status {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-right: 5%;
}
#div_status {
  width: 100%;
  display: flex;
  align-items: center;
}
#p_status {
  width: 80%;
}
