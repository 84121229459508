.acoes {
  background: #F7FAFC 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #2C28281C;
  opacity: 1;
  width: 20%;
  border-radius: 50%;
  height: auto;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
